
$(function() {


	// utils
	// ===============================================================================================

	const pipe = (...functions) => arg => functions.reduce((a, b) => b(a), arg);

	const define = (object, property, value) =>
	Object.defineProperty(object, property, {
		value,
		writable: true,
		configurable: true,
		enumerable: true
	});

	const first = list => list[0];

	const last = list => list[list.length - 1];

	const getRandomInt = (min, max) =>
	Math.floor(Math.random() * (max - min)) + min;
	const getRandomDecimal = (min, max) =>
	Math.random() * (max - min) + min;

	const interval = (callback, delay) => {
		const tick = now => {
			if (now - start >= delay) {
				start = now;
				callback();
			}
			requestAnimationFrame(tick);
		};
		let start = performance.now();
		requestAnimationFrame(tick);
	};


	// easing equations
	// ===============================================================================================

	const easeInQuart = (t, b, c, d) =>
	c * (t/=d) * Math.pow(t, 3) + b;

	const easeOutQuart = (t, b, c, d) =>
	-c * ((t=t/d-1) * Math.pow(t, 3) - 1) + b;

	const easeInOutQuart = (t, b, c, d) => {
		if ((t/=d/2) < 1) return c/2 * Math.pow(t, 4) + b;
		return -c/2 * ((t-=2) * Math.pow(t, 3) - 2) + b;
	};

	const easeInOutBack = (t, b, c, d, s) => {
		if (s == undefined) s = 1.70158;
		if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
		return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
	};

	const easeOutElastic = (t, b, c, d, frequency = 700) => {
		if (t == 0 || c == 0) return b;
		if ((t /= d) == 1) return b + c;
		const a = c;
		const p = d * (1 - Math.min(frequency, 999) / 1000);
		const s = a < Math.abs(c) ? p / 4 : p / (2 * Math.PI) * Math.asin(c / a);
		return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
	};


	// programming languages animation
	// ===============================================================================================

	{

		const getRandomY = (x, min, max) => {
		if (Math.abs(x) > min) return	getRandomInt(-max, max);
		const minY = Math.sqrt(Math.pow(min, 2) - Math.pow(x, 2));
		const randomSign = Math.round(Math.random()) * 2 - 1;
		return randomSign * getRandomInt(minY, max);
	};

		const createIcon = placesAnimation => {
		const icon = document.createElement("img");
		icon.src = `img/exclamation-point-${placesAnimation.getAttribute('id')}.svg`;
		icon.style.opacity = 0;
		placesAnimation.appendChild(icon);
		icon.setAttribute('aria-hidden', true);
		return icon;
	};
		const dataBaseProtection = document.getElementById("data-base-protection");
		const filesModules = document.getElementById("files-modules");
		const containers = [];
		containers.push(dataBaseProtection,filesModules)
		const constainer =	() =>
		containers[getRandomInt(0, containers.length)];

		const animateIcon = icon => {
		const time = { total: 12000 };
		const maxDistance = 120;
		const maxRotation = 800;
		const transform = {};
		icon.style.left = getRandomInt(0, 400)+'px';
		icon.style.top = getRandomInt(0, 300)+'px';
		define(transform, "translateX", getRandomInt(-maxDistance, maxDistance));
		define(transform, "scale", getRandomDecimal(.9, 1));
		define(transform, "translateY", getRandomY(transform.translateX, 60, maxDistance));
		define(transform, "rotate", getRandomInt(-maxRotation, maxRotation));

		const tick = now => {
			if (time.start == null) define(time, "start", now);
			define(time, "elapsed", now - time.start);
			const progress = easeOutQuart(time.elapsed, 0, 1, time.total);
			const progress2 = easeInOutBack(time.elapsed, 0, 1, time.total);
			icon.style.opacity = Math.abs(1 - progress2);
			icon.style.transform = Object.keys(transform).map(key => {
				let value ='';
				let unit = '';
				value = transform[key] * progress;
				unit = (!/scale/.test(key)) ? /rotate/.test(key) ? "deg" : "px": '';
			return `${key}(${value}${unit})`;
		}).join(" ");
const remover = ()=> containers.forEach((e)=>{if (icon.parentNode == e) { e.removeChild(icon) }})
			time.elapsed < time.total
				? requestAnimationFrame(tick)
				: remover(icon);
		};

		requestAnimationFrame(tick);
	};

		interval(pipe( constainer, createIcon , animateIcon), 500);
		// interval(pipe( createIcon , animateIcon), 500);
	}


	// MENU
    var slideout = new Slideout({
        'panel': document.getElementById('main'),
        'menu': document.getElementById('menu'),
        'padding': 130,
        'tolerance': 70,
        'easing':'ease-in-out',
        'duration': 100,
        'side': 'right'
    });

    document.querySelector('.mobile-menu-toggle').addEventListener('click', function() {
        slideout.toggle();
        $('#left-mobile-menu').css('z-index', 1);
    });

    // SLIDER
    var mySwiper = new Swiper ('.swiper-container', {

        loop: true,

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

    });

    // POP-UP
    alertify.buyDialog || alertify.dialog('buyDialog', function(){
        return {
            main:function(data) {
                this.header = data.header;
                this.content = data.content;
            },
            setup:function() {
                return {
                    options: {
                        movable: false,
                        resizable: false,
                        pinnable: false,
                        maximizable: false,
                        // frameless: true
                    }
                };
            },
            prepare:function() {
                this.setHeader(this.header);
                this.setContent(this.content);
            },
        };
    });



});

// SMOOTH SCROLL
function sapphire_scroll(selector)
{
    $('html,body').animate(
        { scrollTop: $(selector).offset().top-20},
        {
            duration: 700,
            specialEasing: {
                width: "linear",
                height: "easeOutBounce"
            },
            complete: function() {}
        }
    );
}

